import moment from '@client/i18n/moment'
import some from 'lodash/some'
import every from 'lodash/every'

const diffInDays = (date1, date2) => {
  return Math.abs(moment(date1).clone().startOf('day').diff(moment(date2).clone().startOf('day'), 'days'))
}

const timeAsInteger = (datetime) => {
  datetime = moment(datetime)
  return (datetime.hours() * 100) + datetime.minutes()
}

const timeStringAsInteger = (timeString) => {
  const [hourString, minuteString] = timeString.split(':')
  return (parseInt(hourString) * 100) + parseInt(minuteString)
}

const isOpeningTimeRelevantToDomain = (openingTime, domainId) => {
  return [domainId, null, undefined].includes(openingTime.domain_id)
}

const isOpeningTimeRelevantToDepot = (openingTime, depotId) => {
  return [depotId, null, undefined].includes(openingTime.depot_id)
}

const openingTimesForDepot = (depot, openingTimes) => {
  return openingTimes.filter((openingTime) =>
    isOpeningTimeRelevantToDomain(openingTime, depot.domain_id) &&
    isOpeningTimeRelevantToDepot(openingTime, depot.id)
  )
}

/**
 * Returns whether given day is open for the given depot.
 */
export const isDayOpen = (date, depot, openingTimes) => {
  const depotOpeningTimes = openingTimesForDepot(depot, openingTimes)
  const dateOpeningTimes = depotOpeningTimes.filter((o) => o.date && o.date === date.format('YYYY-MM-DD'))
  const recurringOpeningTimes = depotOpeningTimes.filter((o) => !o.date && o.weekday === date.day())

  // Check by date
  const dateOpen = every(dateOpeningTimes, (openingTime) => {
    if (openingTime.entire_day) {
      return openingTime.open
    } else {
      return true
    }
  })

  if (!dateOpen) {
    return false
  }

  // Check by recurring day of the week
  return some(recurringOpeningTimes, (openingTime) => {
    return openingTime.open
  })
}

/**
 * Returns whether given timeslot is open for the given depot and date.
 */
export const isTimeslotOpen = (timeslot, date, depot, openingTimes) => {
  if (!isDayOpen(date, depot, openingTimes)) {
    return false
  }

  const depotOpeningTimes = openingTimesForDepot(depot, openingTimes)
    .filter((openingTime) => {
      return !openingTime.entire_day && openingTime.weekday === date.day() || openingTime.date === date.format('YYYY-MM-DD')
    })

  const timeslotFrom = timeStringAsInteger(timeslot.from_time)
  const timeslotTill = timeStringAsInteger(timeslot.till_time)

  return every(depotOpeningTimes, (openingTime) => {
    if (openingTime.entire_day) {
      return openingTime.open
    }

    const from = timeStringAsInteger(openingTime.from_time)
    const till = timeStringAsInteger(openingTime.until_time)

    // Timeslot should overlap the depot opening times
    const withinOpeningTime = Math.max(from, timeslotFrom) < Math.min(till, timeslotTill)

    if (openingTime.open) {
      return withinOpeningTime
    } else {
      return !withinOpeningTime
    }
  })
}

/**
 * Returns whether the given day is disabled for given options.
 */
export const isDayDisabled = ({ day, type, pickupAt, returnAt, pickupDepot, returnDepot, openingTimes, firstAvailableAt }) => {
  const date = moment(day)

  // Disable days before the first available moment
  if (firstAvailableAt && date.isBefore(moment(firstAvailableAt), 'day')) {
    return true
  }

  // Disabled past
  if (date < moment().startOf('day')) {
    return true
  }

  // Return day not before pickup day
  if (type === 'return' && pickupAt && date.startOf('day') < pickupAt.clone().startOf('day')) {
    return true
  }

  // Closed pickup day
  if (type === 'pickup' && pickupDepot && !isDayOpen(date, pickupDepot, openingTimes)) {
    return true
  }

  // Closed return day
  if (type === 'return' && returnDepot && !isDayOpen(date, returnDepot, openingTimes)) {
    return true
  }

  return false
}

/**
 * Returns whether the given timeslot is disabled for given options.
 */
export const isTimeslotDisabled = ({ timeslot, type, pickupAt, returnAt, pickupDepot, returnDepot, openingTimes, firstAvailableAt }) => {
  // Disable timeslots before the first available moment
  if (type === 'pickup' && firstAvailableAt && pickupAt.isSame(moment(firstAvailableAt), 'day')) {
    if (timeslot.from_time_integer < timeAsInteger(firstAvailableAt)) {
      return true
    }
  }

  // Disable past pickups
  if (type === 'pickup' && pickupAt && diffInDays(pickupAt, moment()) === 0) {
    if (timeslot.till_time_integer < timeAsInteger(moment())) {
      return true
    }
  }

  // Disable past returns
  if (type === 'return' && returnAt && diffInDays(returnAt, moment()) === 0) {
    if (timeslot.from_time_integer < timeAsInteger(moment())) {
      return true
    }
  }

  // Pickup and return are on the same day
  if (pickupAt && returnAt && diffInDays(pickupAt, returnAt) === 0) {
    // Disable pickup time later than return time
    if (type === 'pickup') {
      if (timeslot.till_time_integer > timeAsInteger(returnAt)) {
        return true
      }
    }

    // Disable return time earlier than pickup time
    if (type === 'return') {
      if (timeslot.from_time_integer < timeAsInteger(pickupAt)) {
        return true
      }
    }
  }

  // Return time is later than 60 days
  if (diffInDays(returnAt, pickupAt) >= 60) {
    return true
  }

  // Closed pickup timeslot
  if (type === 'pickup' && pickupAt && pickupDepot && !isTimeslotOpen(timeslot, pickupAt, pickupDepot, openingTimes)) {
    return true
  }

  // Closed return timeslot
  if (type === 'return' && returnAt && returnDepot && !isTimeslotOpen(timeslot, returnAt, returnDepot, openingTimes)) {
    return true
  }

  return false
}
