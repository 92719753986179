import React from 'react'

// Utils
import { t, translatePeriodLabel } from '@client/i18n/localize'

// Components
import AddToCart from './AddToCart'
import AddToWishlist from './AddToWishlist'
import Price from './Price'

const getLabel = ({ isNew, expected }) => {
  if (expected) {
    return {
      label: t('expected'),
      color: 'bg-dark text-white'
    }
  } else if (isNew) {
    return {
      label: t('new'),
      color: 'bg-green text-white'
    }
  } else {
    return false
  }
}

const Label = ({ label, color }) => {
  return <span className={`label ${color}`}>{label}</span>
}

const renderItem = ({ title, label, formattedPrice }) => {
  return (
    <div className="product-tile__rental-period">
      { translatePeriodLabel(title || label) }
      <span className="product-tile__rental-period__price">{formattedPrice}</span>
    </div>
  )
}

const PriceComponent = ({ prices }) => {
  return (
    prices.map((price, index) => <React.Fragment key={index}>{renderItem(price)}</React.Fragment>)
  )
}

const ExpectedProduct = ({ id, name, path, imageThumbLarge, imageThumb, expected, onClick }) => {
  const label = getLabel({ expected })

  return (
    <div className={`product-tile d-flex flex-column justify-content-between ${expected ? 'expected' : ''}`}>
      <div className="product-tile__image">
        {label && <Label {...label} />}
        <AddToWishlist id={id} name={name} image={imageThumb} />
        <a href={path} onClick={onClick} className="product-tile__image-link d-flex align-items-center justify-content-center">
          <img src={imageThumbLarge} alt={name} loading="lazy" />
        </a>
      </div>
      <a href={path} className="product-tile__name" onClick={onClick}>
        {name}
      </a>
    </div>
  )
}

const BookableProduct = ({ id, name, path, imageThumbLarge, imageThumb, prices, isNew, expected, wishlist, source, onClick, optionalPickupAccessories }) => {
  const label = getLabel({ isNew, expected })

  return (
    <div className="product-tile d-flex flex-column justify-content-between">
      <div className="product-tile__image">
        {label && <Label {...label} />}
        {wishlist !== false && <AddToWishlist id={id} name={name} image={imageThumb} />}
        <a href={path} onClick={onClick} className="product-tile__image-link d-flex align-items-center justify-content-center">
          <img src={imageThumbLarge} alt={name} loading="lazy" />
        </a>
      </div>
      <a href={path} className="product-tile__name" onClick={onClick}>
        {name}
      </a>
      <div className="d-flex align-items-end gap-1">
        <div className="hstack col align-items-start gap-3">
          <Price id={id} initialPrice={prices} component={PriceComponent} />
        </div>
        <AddToCart id={id} name={name} type="button" source={source} optionalPickupAccessories={optionalPickupAccessories} />
      </div>
    </div>
  )
}

const Tile = (product) => {
  if (product.expected) {
    return <ExpectedProduct {...product} />
  } else {
    return <BookableProduct {...product} isNew={product.new} />
  }
}

export default Tile
