const fakeTracker = (...args) => {
  console.log('ga', ...args)
}

const tracker = () => {
  return window.ga && typeof window.ga === 'function' ? window.ga : fakeTracker
}

const analyticsAllowed = () => {
  return window.CookieConsent.acceptedCategory('analytics')
}

const listSources = {
  essentials: 'Essential list',
  quicksearch: 'Quicksearch'
}

const track = (event, data) => {
  const ga = tracker()

  if (event !== 'page.visit' && !analyticsAllowed()) {
    return
  }

  // Always anonymize IP addresses (last three digits IP4)
  ga('set', 'anonymizeIp', true);

  switch (event) {
    case 'page.visit': {
      ga('set',  'location', data.url)
      ga('send', 'pageview', { 'title': document.title })

      break
    }
    case 'customer': {
      if (data.category) ga('set', 'dimension1', data.category)
      if (data.segment) ga('set', 'dimension2', data.segment)

      ga('send', 'event', 'customer', 'information', { 'nonInteraction': 1 })

      break
    }
    case 'cart.book_product': {
      const line = data.cart.carts_products.find((line) => line.product_id === data.productId)

      ga('ec:addProduct', {
        'id': line.product.id,
        'name': line.product.name,
        'quantity': 1
      })
      ga('ec:setAction', 'click', { 'list': data.source })
      ga('send', 'event', 'UX', 'click', 'Add to cart')

      break
    }
    case 'cart.change_product_quantity': {
      ga('ec:addProduct', {
        'id': data.product.id,
        'name': data.product.name,
        'quantity': Math.abs(data.newQuantity)
      })

      if (data.newQuantity > 0) {
        ga('ec:setAction', 'click', { 'list': 'Cart' })
        ga('send', 'event', 'automatic', 'click', 'Add to cart', { 'nonInteraction': 1 })
      } else {
        ga('ec:setAction', 'remove')
        ga('send', 'event', 'UX', 'click', 'Remove from cart')
      }

      break
    }
    case 'checkout.cart':
    case 'checkout.sign_in':
    case 'checkout.information': {
      const checkoutSteps = {
        'checkout.cart': 1,
        'checkout.sign_in': 2,
        'checkout.information': 3
      }

      data.products.forEach((product) => { ga('ec:addProduct', product) })
      ga('ec:setAction','checkout', { step: checkoutSteps[event] })

      ga('send', 'event', 'checkout', event.split('.')[1]);

      break
    }
    case 'checkout.confirmation': {
      data.products.forEach((product) => {
        ga('ec:addProduct', product)
      })

      ga('ec:setAction','purchase', { id: data.id, revenue: data.total })

      window.goog_report_conversion && window.goog_report_conversion()

      ga('send', 'event', 'checkout', 'confirmation');

      break
    }
    case 'product.impression': {
      data.products.forEach((product) => {
        ga('ec:addImpression', {
          name: product.name,
          id: product.id,
          list: listSources[data.source] || data.source
        })
      })

      if (data.source === 'essentials') {
        ga('send', 'event', 'automatic', 'click', 'Select essentials', { 'nonInteraction': 1 })
      } else {
        ga('send', 'event', 'product', 'impression', { 'nonInteraction': 1 });
      }

      break
    }
    case 'product.view': {
      ga('ec:addProduct', data.product)
      ga('ec:setAction', 'detail')
      ga('send', 'event', 'product', 'view', data.product.name);

      break
    }
    case 'product.click': {
      const sources = {
        quicksearch: 'Product in quicksearch'
      }

      ga('ec:addProduct', {
        'name': data.product.name,
        'id': data.product.id
      })
      ga('ec:setAction', 'click', {
        'list': listSources[data.source] || data.source
      })
      ga('send', 'event', 'UX', 'click', sources[data.source] || data.source)
    }
  }
}

export default track
