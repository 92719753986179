// Libraries
import moment from '@client/i18n/moment'

// Utils
import { isDayDisabled, isTimeslotDisabled } from '@client/utils/openingTimes'
import { t } from '@client/i18n/localize'

/*
  props: {
    pickupDate,
    pickupTime,
    pickupTimeslot,
    pickupDepot,
    returnDate,
    returnTime,
    returnTimeslot,
    returnDepot,
    openingTimes
  }
*/
class Validation {
  constructor(props) {
    this.props = {}
    Object.assign(this.props, props)
  }

  get firstAvailableAt() {
    return this.props.pickupDepot ? moment(this.props.pickupDepot.first_available_at).toDate() : null
  }

  isDayAvailable(day, type) {
    return !isDayDisabled({
      day,
      type,
      pickupAt: this.props.pickupTime,
      returnAt: this.props.returnTime,
      pickupDepot: this.props.pickupDepot,
      returnDepot: this.props.returnDepot,
      openingTimes: this.props.openingTimes,
      firstAvailableAt: this.firstAvailableAt
    })
  }

  isTimeslotAvailable(timeslot, type) {
    return !isTimeslotDisabled({
      timeslot,
      type,
      pickupAt: this.props.pickupTime,
      returnAt: this.props.returnTime,
      pickupDepot: this.props.pickupDepot,
      returnDepot: this.props.returnDepot,
      openingTimes: this.props.openingTimes,
      firstAvailableAt: this.firstAvailableAt
    })
  }

  valuesFor(type) {
    switch (type) {
      case 'pickup':
        return {
          date: this.props.pickupDate,
          depot: this.props.pickupDepot,
          timeslot: this.props.pickupTimeslot
        }
      case 'return':
        return {
          date: this.props.returnDate,
          depot: this.props.returnDepot,
          timeslot: this.props.returnTimeslot
        }
    }
  }

  get errors() {
    const types = ['pickup', 'return']
    const errors = []

    if (this.props.pickupTime && this.props.returnTime) {
      const diffDays = this.props.returnTime.diff(this.props.pickupTime, 'days')
      const pickupAfter = this.props.pickupTime.isAfter(this.props.returnTime)

      if (diffDays >= 30) {
        errors.push({
          message: t('shopping_cart.periode_error_duration'),
          error: { data: 'date', type: 'return', value: this.props.returnDate }
        })
      }

      if (pickupAfter) {
        errors.push({
          message: t('shopping_cart.periode_error_return_before_pickup'),
          error: { data: 'date', type: 'return', value: this.props.returnDate }
        })
      }
    }

    types.forEach((type) => {
      const value = this.valuesFor(type)

      if (!value.date || !value.depot || !value.timeslot) return null

      if (!this.isDayAvailable(value.date, type)) {
        errors.push({
          message: t(`shopping_cart.periode_error_${type}.date`),
          error: { data: 'date', type, value: value.date }
        })
      } else if (!this.isTimeslotAvailable(value.timeslot, type)) {
        errors.push({
          message: t(`shopping_cart.periode_error_${type}.timeslot`),
          error: { data: 'timeslot', type, value: value.timeslot }
        })
      }
    })

    return errors
  }
}

export default Validation
