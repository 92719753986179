import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import AddOptionalAccessories from '@client/react/components/AddOptionalAccessories'
import { clearCart } from '@client/redux/actions/cart'
import { updateQuantity, updateCartsProduct } from '@client/redux/actions/carts_products'
import { t, translatePeriodLabel } from '@client/i18n/localize'
import { track } from '@client/utils/tracking'
import { productPath } from '@client/utils'

import OptionalAccessoryProduct from './OptionalAccessoryProduct'

const CartProduct = ({ id, product, qty, formattedTotal, charge_label, accessories }) => {
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(qty)
  const path = productPath(product.slug)

  const [visible, setVisible] = useState(false)
  const hide = () => setVisible(false)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)

  const handlePopover = () => {
    setVisible(!visible)
  }

  const changeQuantity = (newQuantity) => {
    if (quantity !== newQuantity) {
      dispatch(updateQuantity({ id: id, type: 'change', quantity: newQuantity })).then(({ payload }) => {
        track('cart.change_product_quantity', {
          oldQuantity: quantity,
          newQuantity,
          product,
          cart: payload.cart
        })
      })
    }
  }

  const debouncedChangeQuantity = _.debounce(changeQuantity, 300)

  const handleChangeQuantity = (newQuantity) => {
    setQuantity(newQuantity)
    debouncedChangeQuantity(newQuantity)
  }

  const handleIncreaseQuantity = () => {
    handleChangeQuantity(quantity + 1)
  }

  const handleDecreaseQuantity = () => {
    handleChangeQuantity(quantity - 1)
  }

  const handleDelete = () => {
    handleChangeQuantity(0)
  }

  const handleQuantityInput = (e) => {
    let newQuantity

    if (e.target.value === '') {
      newQuantity = 0
    } else {
      newQuantity = _.max([parseInt(e.target.value), 1])
    }

    handleChangeQuantity(newQuantity)
  }

  const handleUpdateAccessories = ({ accessoryIds = [] }) => {
    setLoading(true)

    dispatch(updateCartsProduct({ id, accessoryIds }))
      .then(({ payload }) => {
        if (!payload.cart) { throw ('No cart') }

        setSuccess(true)
        setLoading(false)
        hide()

        track('cart.book_product', {
          productId: id,
          cart: payload.cart,
          source
        })

        setTimeout(() => { setSuccess(null) }, 2000)
      })
      .catch(() => {
        setSuccess(false)
        setLoading(false)
        hide()
      })
  }

  const renderAccessoryPopover = () => {
    const component = <i className="fas fa-edit text-primary text-large cart-item__change" role="button" onClick={() => handlePopover()} />

    return (
      <AddOptionalAccessories
        visible={visible}
        onClose={() => hide()}
        onClick={(props) => handleUpdateAccessories(props)}
        accessories={product.optional_pickup_accessories}
        selectedAccessories={accessories}
        loading={loading}
        success={success}
        productName={product.name}
        type="edit"
      >{component}</AddOptionalAccessories>

    )
  }

  if (quantity === 0) return null

  return (
    <div className="cart-item hstack border-bottom gap-3 gap-sm-4 py-3">
      <div className="col-auto">
        <a href={path}><img className="img-fluid" src={product.imageThumbLarge} width="80px" /></a>
      </div>
      <div className="col vstack gap-2">
        <a href={path} className="link-dark d-block">{product.name}</a>
        <div className="hstack gap-3 gap-sm-4">
          <i className="fas fa-trash-alt text-large text-primary cart-item__remove" role="button" onClick={handleDelete} />
          <div className="hstack">
            <i className="fas fa-minus cart-qty__reduce" onClick={handleDecreaseQuantity} />
            <span className="cart-qty__amount d-md-none">{quantity}</span>
            <input
              onChange={handleQuantityInput}
              className="cart-qty__amount d-none d-md-block"
              type="number"
              value={quantity}
            />
            <i className="fas fa-plus cart-qty__increase" onClick={handleIncreaseQuantity} />
          </div>
          <div className="col-auto ml-auto text-right">
            <div className="text-medium text-large">{formattedTotal}</div>
            <div className="text-gray">{translatePeriodLabel(charge_label)}</div>
          </div>
        </div>
        {accessories.length > 0 &&
          <div className="d-flex border rounded-sm p-2 bg-light">
            <div className="col">
              <span className="text-medium">{t("accessories")}</span>
              <OptionalAccessoryProduct accessories={accessories} />
            </div>
            <div className="col-auto">
              {renderAccessoryPopover()}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const Products = ({ showClearCart = true }) => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)

  const cartsProducts = cart.carts_products
  const mainProducts = cartsProducts.filter(item => item.parent_id === null)
  const accessoryProducts = cartsProducts.filter(item => item.parent_id !== null)

  const handleClickClear = (e) => {
    e.preventDefault()

    dispatch(clearCart())
    window.location.reload()
  }

  const getSelectedAccessoriesForProduct = (cartsProductId) => {
    return accessoryProducts.filter(item => item.parent_id === cartsProductId)
  }

  return (
    <>
      <div className="hstack gap-3 justify-content-between py-3">
        <h1 className="h2 mb-0">{t('shopping_cart.title')}</h1>
        {showClearCart && (
          <button className="btn btn-link" onClick={handleClickClear} type="button">
            {t('shopping_cart.clear')}
          </button>
        )}
      </div>

      {
        mainProducts.map((cartsProduct) => (
          <CartProduct key={cartsProduct.id}
                        accessories={getSelectedAccessoriesForProduct(cartsProduct.id)}
                        {...cartsProduct} />
        ))
      }
    </>
  )
}

export default Products
