import gaTrack from './ga'
import gtmTrack from './gtm'
import fbqTrack from './fbq'

const isLocal = () => {
  return window.ENVIRONMENT === 'development' || window.ENVIRONMENT === 'test'
}

const analyticsAllowed = () => {
  return window.CookieConsent.acceptedCategory('analytics')
}

const marketingAllowed = () => {
  return window.CookieConsent.acceptedCategory('marketing')
}

const trackEventAcrossServices = (event, data) => {
  if (isLocal()) {
    console.log('Tracking functional event', event, data)
  } else {
    gaTrack(event, data)
  }

  if (marketingAllowed()) {
    if (isLocal()) {
      console.log('Tracking marketing event', event, data)
    } else {
      fbqTrack(event, data)
      // GTM can load other scripts like Bing, so we need to be more restrictive here.
      gtmTrack(event, data)
    }
  }
}

const track = (event, data) => {
  if (document.readyState !== 'complete') {
    if (isLocal()) console.log('Tracking.track', 'page still loading', event)

    window.addEventListener('load', () => {
      // Try again when the page has loaded
      track(event, data)
    })

    return
  }

  trackEventAcrossServices(event, data)
}

export { track }
