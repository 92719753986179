import React from 'react'
import { DayPicker } from 'react-day-picker'

import { getDateFnsLocale } from '@client/i18n/localize'

const Calendar = ({ type, selectedDay, onChange, isDayAvailable, firstAvailableAt, range }) => {
  const unavailable = day => {
    return !isDayAvailable(day, type)
  }

  const handleClickDay = (day, modifiers = {}) => {
    if (modifiers.disabled || modifiers.unavailable) {
      return
    }

    onChange(day)
  }

  return (
    <DayPicker
      defaultMonth={selectedDay || new Date()}
      disabled={[
        {
          before: firstAvailableAt || new Date()
        },
        unavailable
      ]}
      selected={[range.from, range.to]}
      modifiers={{
        period: range,
        active: selectedDay,
        start: range.from,
        end: range.to
      }}
      modifiersClassNames={{
        active: 'rdp-day-active',
        period: 'rdp-day-period',
        start: 'rdp-day-start',
        end: 'rdp-day-end',
      }}
      locale={getDateFnsLocale()}
      onDayClick={handleClickDay}
      showOutsideDays
    />
  )
}

export default Calendar
